// #######################################
// Plugins
// #######################################
@import '../../node_modules/prismjs/plugins/line-numbers/prism-line-numbers.min.css';
@import '../../node_modules/prismjs/themes/prism-coy.min.css';

@import './helpers/media-queries';
.sg{
	&__body{
		background-color: var(--contrast-white);
	}

	&__sidebar{
		z-index: 1000;
		background-color: var(--contrast-light-2);
		position: fixed;
		left: 0;
		right: auto;
		bottom: 0;
		width: 240px;
		height: 0;
		border-right-style: solid;
		top: 0;
		border-right-width: 1px;
		border-right-color: var(--contrast-gray-1);

		@include mq-medium{
			width: 280px;
		}

		@include mq-tablet{
			overflow: visible;
			position: sticky;
			left: 0;
			right: 0;
			bottom: auto;
			z-index: 10;
			width: 100%;
			height: 80px;
			padding-top: 20px;
			border-right-style: none;
			background-color: var(--contrast-light-2);
		}

		&:before {
			content: " ";
			display: table;
			grid-column-start: 1;
			grid-row-start: 1;
			grid-column-end: 2;
			grid-row-end: 2;
		}

		&:after {
			content: " ";
			display: table;
			grid-column-start: 1;
			grid-row-start: 1;
			grid-column-end: 2;
			grid-row-end: 2;
			clear: both;
		}
	}

	&__sidebar-content{
		background-color: var(--contrast-light-1);
		height: 100vh;
		width: 240px;
		overflow-y: auto;
		@include mq-medium{
			width: 280px;
		}

		@include mq-tablet{
			height: auto;
			width: auto;
			background-color: var(--contrast-light-2);
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			padding-bottom: 10px;
		}
	}

	&__logo{
		text-decoration: none;
		background-color: transparent;
		position: relative;
		float: left;
		color: var(--contrast-dark-3);
		margin-left: 30px;
		margin-top: 40px;
		margin-bottom: 50px;
		padding-left: 0;
		max-width: 100px;
		display: flex;
		gap: 12px;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		@include mq-medium{
			margin-top: 4px;
			margin-bottom: 16px;
			max-width: initial;
			float: none;
			display: inline-flex;
		}
		@include mq-mobile{
			margin-left: 16px;
		}

		&:active {
			outline: 0;
		}

		&:hover {
			outline: 0;
		}

		img{
			width: 48px;
			height: 48px;
		}

		span{
			font-weight: 600;
			font-size: 18px;
			word-wrap: break-word;
			line-height: 1.1;
			display: inline-flex;
			@include mq-medium{
				word-wrap: normal;
				white-space: nowrap;
			}
			@include  mq-mobile{
				display: none;
			}
		}

	}

	&__nav{
		display: block;
		position: relative;
		float: right;
		width: 100%;

		@media only screen and (min-width: 1023.99px) {
			position: relative !important;
			display: block !important;
			max-height: initial !important;
		}

		@include mq-tablet{
			background-color: var(--contrast-light-1);
			position: absolute;
			left: 0;
			top: 80px;
			right: auto;
			bottom: auto;
			z-index: 500;
			display: none;
		}
		@include mq-tablet-small{
			background-color: var(--contrast-white);

		}

		&.active{
			padding-top: 20px;
			padding-bottom: 20px;
			border-bottom-width: 1px;
			border-bottom-style: solid;
			border-bottom-color: var(--contrast-gray-1);
			box-shadow: rgba(0, 0, 0, 0.05) 0 3px 5px 0;
		}

		&-link{
			transition: background-color 200ms, color 200ms;
			text-decoration: none;
			background-color: transparent;
			position: relative;
			display: inline-block;
			vertical-align: top;
			text-align: left;
			margin-left: auto;
			margin-right: auto;
			width: 100%;
			padding: 7px 20px 7px 27px;
			border-left-width: 4px;
			border-left-style: solid;
			border-left-color: transparent;
			transition-behavior: normal, normal;
			color: var(--contrast-dark-1);
			cursor: pointer;
			font-weight: 600;

			&:active {
				outline: 0;
			}

			&:hover {
				outline: 0;
			}

			&.is-active{
				color: var(--base-primary-1);
				background-color: var(--contrast-light-3);
			}
		}
	}

	&__hamburger{
		flex: 1;
		justify-content: flex-end;
		align-items: center;
		display: none;

		@include mq-tablet{
			display: flex;
		}

		&-icon{
			cursor: pointer;
			color: var(--contrast-dark-4);
			height: 100%;
			padding: 0 32px;
			transition: transform .3s, -webkit-transform .3s;
			display: flex;
			position: relative;

			&-inner{
				transition-behavior: normal, normal;
				will-change: transform;
				align-items: center;
				width: 24px;
				height: 11px;
				margin: -8px 0 0;
				transition: transform .3s, -webkit-transform .3s;
				display: flex;
				position: relative;
			}

			&-line{
				vertical-align: baseline;
				transition-behavior: normal, normal;
				will-change: transform;
				background-color: currentColor;
				outline: 0;
				width: 100%;
				height: 1px;
				font-size: 100%;
				transition: transform .3s, -webkit-transform .3s;

				&:before,
				&:after{
					content: "";
					transition-behavior: normal, normal;
					will-change: transform;
					opacity: 0;
					background-color: currentColor;
					width: 100%;
					height: 1px;
					transition: transform .3s, -webkit-transform .3s;
					position: absolute;
					right: 0;
				}

				&:before{
					transform: translateY(0);
				}
				&:after{
					opacity: 1;
					transform: translateY(7px);
				}
			}

			&.active{
				.sg__hamburger-icon{
					&-inner{
						transform: rotate(45deg);
					}
					&-line{
						&:before,
						&:after{
							opacity: 0;
							transform: rotate(90deg);
						}

						&:after{
							opacity: 1;
							width: 100%;
						}
					}
				}
			}
		}
	}

	&__content{
		align-items: center;
		justify-content: center;
		flex-direction: column;
		max-width: 100%;
		display: flex;
		margin-left: 240px;
		@include mq-medium{
			margin-left: 280px;
		}

		@include mq-tablet{
			border-left-style: none;
			width: 100%;
			position: static;
			margin-left: 0;
		}

		@include mq-tablet-small{
			margin-left: 0;
		}

		.grid-layout {
			grid-column-gap: 32px;
			grid-row-gap: 24px;
		}

		&-box{
			width: 100%;
			height: 80px;
			border-radius: 6px;
			// background-color: var(--base-primary-1);
		}
	}

	&__wrapper{
		width: 100%;
		min-height: 100vh;
	}

	&__section{
		position: relative;
		z-index: 0;
		padding: 60px 80px 60px 60px;
		text-align: left;

		@include mq-tablet-small{
			padding-right: 30px;
			padding-left: 30px;
		}

		@include mq-mobile{
			padding-right: 16px;
			padding-left: 16px;
		}

		&.is-header{
			background-color: var(--base-primary-1);
			color: var(--contrast-white);

			a{
				text-decoration: none;
				font-weight: 600;
				color: var(--contrast-light-3);
				&:hover{
					color: var(--contrast-white)
				}
			}
		}

		&-header{
			max-width: 540px;
			min-height: 64px;
			h1{
				margin-bottom: 24px;
			}
			p{
				font-weight: 600;
			}

			a{
				color: var(--contrast-white);
				opacity: .85;
			}

			&.is--variant-2{
				margin-bottom: 0px;

				h2{
					margin-bottom: 8px;
				}

				p{
					font-size: 16px;
					font-weight: 500;
					color: var(--contrast-gray-1);
				}
			}
		}

		&-content{
			width: 100%;
		}

		&-line{
			width: 100%;
			height: 1px;
			margin-bottom: 36px;
			background-color: var(--contrast-dark-4);
			opacity: 0.2;
			position: relative;
			bottom: -80px;
		}
	}

	&__title{
		margin-bottom: 8px;
		color: var(--contrast-dark-1);
	}
	&__description{
		max-width: 400px;
		color: var(--contrast-gray-1);
	}

	&__subtitle{
		color: var(--contrast-dark-1);
		font-size: 18px;
		margin-bottom: 8;
	}

	&__footer{
		position: relative;
		z-index: 0;
		padding: 60px 80px 60px 60px;
		text-align: left;

		@include mq-tablet-small{
			padding-right: 30px;
			padding-left: 30px;
		}

		@include mq-mobile{
			padding-right: 16px;
			padding-left: 16px;
		}

		p{
			color: var(--contrast-dark-1);

			a{
				color: var(--base-primary-1);
				text-decoration: none;
				&:hover{
					color: darken(#2A85FF, 10%);
				}
			}
		}
	}

	&__code-block{
		overflow: hidden;
		> pre{
			margin: -24px 0 -48px;
			// position: relative;
			&::before,
			&::after{
				display: none;
			}
		}
		/* CSS untuk menampilkan nomor baris */
		.line-number {
			color: #888;
			display: inline-block;
			width: 30px;
			text-align: right;
			margin-right: 10px;
			user-select: none;
		}

		/* CSS untuk menampilkan label bahasa */
		.language-label {
			position: absolute;
			right: 10px;
			top: 10px;
			background: rgba(0, 0, 0, 0.7);
			color: #fff;
			padding: 2px 8px;
			border-radius: 3px;
			font-size: 12px;
			text-transform: capitalize;
			pointer-events: none;
		}
	}

	&__column-styleguide{
		height: 40px;
		width: 100%;
		border-radius: 6px;
		background-color: rgba(#CABDFF, .3);
		border: 1px dashed var(--base-secondary-2);
	}

	&__label{
		display: inline-flex;
		margin-bottom: 4px;
		// text-transform: uppercase;
		font-weight: 600;
		color: var(--contrast-gray-1);
		font-size: 14px;
		letter-spacing: 0.5px;

		&.is-class{
			color: var(--base-primary-1);
			text-transform: none;
			font-family: 'Courier New', Courier, monospace;
		}

	}

	&__space-helper{
		height: 24px;
		width: 100%;
	}

	&__content-grid{
		position: relative;
		overflow: hidden;
		padding-bottom: 24px;
		margin-bottom: 40px;
		&::after{
			content: "";
			width: 100%;
			height: 1px;
			border-bottom: 1px solid var(--contrast-dark-4);
			bottom: 0;
			left: 0;
			position: absolute;
			opacity: 0.1;
		}
		&.clear{
			padding-bottom: 0;
			margin-bottom: 0;
			&::after{
				display: none;
			}
		}
	}

	// Customization
	&__section-content{
		[class*="spacer-"]{
			.sg__column-styleguide{
				height: 100%;
			}
		}
	}
}

#other-html-tags{
	// [class*="flex-col-"]{
	// 	> .sg__section-content{
	// 		height: 100%;
	// 	}
	// }
}
